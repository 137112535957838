import 'owl.carousel';

jQuery( document ).ready( function ( $ ) {
	$( '#about .owl-carousel' ).owlCarousel( {
		loop: true,
		items: 1,
		dots: false,
		nav: true,
		navText: [
			'<i class="far fa-arrow-left" aria-label="true"></i><span class="sr-only">Prev</span>',
			'<i class="far fa-arrow-right" aria-label="true"></i><span class="sr-only">Next</span>'
		]
	} );
} );
